






























































































































































































































import Vue from 'vue'
import { getCategoryList, getStoreListWithFilters, setStoreStatus, auditStore, addStore as submitADD, editStore } from '@/api/store'
import { ElForm } from 'element-ui/types/form'
import { getOssUrl } from '@/api/common'
import { getUploadFileUrl } from '@/api/common'
import { adminToken } from '@/libs/local-store'
import { regionData } from 'element-china-area-data'
import { getHotelList } from '@/api/hotel'
import { getAdminInfo } from '@/api/admin'

export default Vue.extend({
	inject: ['oss56'],
	data() {
		return {
			btndisabled: false,
			activeName: '4',
			labelPosition: 'right',
			store_category_list: [] as Array<any>,
			examine_status_list: [
				// 审核状态:0=审核中,1=已通过,2=已拒绝
				{ id: 0, name: '审核中' },
				{ id: 1, name: '已通过' },
				{ id: 2, name: '已拒绝' },
			] as Array<any>,
			statusList: [
				{ id: 0, name: '禁用' },
				{ id: 1, name: '正常' },
			],
			searchForm: {
				mobile: '',
				seller_name: '',
				create_time: '',
				examine_status: '',
				hotel_id: '',
			},
			page: 1,
			size: 10,
			table_state: {
				loading: true,
			},
			total: 0,
			table_data: [],
			reason_submit_form: {
				refuse_info: '',
			},
			showDialog: false,

			editId: '',
			baseUrl: '',
			store_submit_form_title: '新增入驻商户',
			showDialogStore: false,
			store_submit_form: {
				seller_name: '',
				mobile: '',
				discount: 10,
				commission_rate: 0,
				business_license: '',
				area_code: [] as any,
				id_card_image1: '',
				id_card_image2: '',
				id_card_image: [] as any,
				hotel_id: '',
				hotel_name: '',
				area_info: [] as any,
				store_category_id: '',
				other_image: '',
			},
			store_submit_form_rules: {
				seller_name: [{ required: true, message: '请输入业主姓名', trigger: 'blur' }],
				mobile: [
					{ required: true, message: '联系电话必填', trigger: 'blur' },
					{ pattern: /^1[3456789]\d{9}$/, message: '联系电话格式不正确', trigger: 'blur' },
				],
				area_code: [{ required: true, message: '请选择申请区域', trigger: 'change' }],

				business_license: [{ required: true, message: '请上传营业执照', trigger: 'change' }],
				other_image: [
					{
						validator: (rule, value, callback) => {
							// if((this as any).store_submit_form.other_image )
							if ((this as any).store_submit_form.store_category_id == '1') {
								if ((this as any).store_submit_form.other_image == '') {
									callback(new Error('请上传证件'))
								}
							}
							callback()
						},
						trigger: 'change',
					},
				],
				discount: [
					{
						validator: (rule, value, callback) => {
							if ((this as any).store_submit_form.discount <= 0 || (this as any).store_submit_form.discount > 10) {
								callback(new Error('请输入有效折扣(0<折扣≤10)'))
							}
							callback()
						},
						trigger: 'change',
					},
				],
				hotel_id: [{ required: true, message: '请选择绑定酒店', trigger: 'change' }],
				refuse_info: [{ required: true, message: '请输入驳回原因', trigger: 'blur' }],
				store_category_id: [{ required: true, message: '请选择经营类别', trigger: 'change' }],
				id_card_image: [
					{
						validator: (rule, value, callback) => {
							if ((this as any).store_submit_form.id_card_image1 == '' || (this as any).store_submit_form.id_card_image2 == '') {
								callback(new Error('上传完整身份证信息'))
							}
							callback()
						},
						trigger: 'blur',
					},
				],
			},
			fileList: [] as any,
			HotelList: [] as any,
			admin_id: '' as any,
			regionData,
			TOKEN: '',
			getUploadFileUrl: '',
			hotel_ids: [],
		}
	},
	computed: {
		returnTitle() {
			if (this.store_submit_form.store_category_id == '1') {
				return '食品经营许可证/餐饮经营许可证'
			} else {
				return '其他'
			}
		},
	},
	created() {
		this.TOKEN = adminToken.get()
		this.getUploadFileUrl = getUploadFileUrl()
		getOssUrl().then((res) => {
			this.baseUrl = res.data.domain
		})
		getCategoryList().then((res) => {
			if (res.code == 0) {
				this.store_category_list = res.data as any
				// console.log('this.store_category_list', this.store_category_list)
			}
		})
		getHotelList().then((res) => {
			if (res.code == 0) {
				// <!-- 1 营业 2维护 3停业 -->
				this.HotelList = res.data.rows.filter((item) => {
					return item.state == 1
				})
			}
		})
		getAdminInfo().then((res) => {
			if (res.code == 0) {
				this.admin_id = (res.data as any).id
				this.hotel_ids = (res.data as any).hotel_ids
				this.getStoreListWithFilters()
			}
		})
	},
	methods: {
		// 返回 商铺类型
		returnStoreType(type) {
			let find = this.store_category_list.find((item) => {
				return item.id == type
			})

			if (find) {
				return find.name
			} else {
				return ''
			}
		},
		changeHotelValue(event) {
			let find = this.HotelList.find((item) => {
				return item.id == event
			})
			if (find) {
				this.store_submit_form.hotel_name = find.name
			}
		},
		// 改变申请区域值
		changeAreaAdcode(value) {
			this.store_submit_form.area_info = value
				.map((val) => {
					let selectedItem = this.findItemById(this.regionData, val)
					return selectedItem ? { value: selectedItem.value, label: selectedItem.label } : null
				})
				.filter((item) => item) // 过滤掉未找��的项
		},
		findItemById(options, value) {
			for (let option of options) {
				if (option.value === value) {
					return option
				}
				if (option.children && option.children.length) {
					let result = this.findItemById(option.children, value)
					if (result) return result
				}
			}
			return null
		},
		// 新增商户
		addStore() {
			this.store_submit_form_title = '新增入驻商户'
			this.fileList = []
			this.showDialogStore = true
		},
		handleClick(tab) {
			this.page = 1
			let status = tab.name
			if (status == '4') {
				this.searchForm.examine_status = ''
			} else {
				this.searchForm.examine_status = status
			}
			this.getStoreListWithFilters()
		},
		// 关闭DIA
		closeDialog2() {},
		getStoreListWithFiltersChange() {
			this.page = 1
			this.getStoreListWithFilters()
		},
		//  获取商户列表
		getStoreListWithFilters() {
			this.table_state.loading = true
			getStoreListWithFilters({
				...this.searchForm,
				page: this.page,
				size: this.size,
				hotel_ids: this.hotel_ids,
			})
				.then((res) => {
					if (res.code == 0) {
						if (res.data.data) {
							this.table_data = res.data.data.map((item) => {
								return {
									addressDetail: item.area_info ? item.area_info.join('') + item.address : '',
									...item,
									id_card_image1: item.id_card_image ? item.id_card_image[0] : '',
									id_card_image2: item.id_card_image ? item.id_card_image[1] : '',
								}
							})
							this.total = res.data.total
						} else {
							this.table_data = []
							this.total = 0
						}
					}
				})
				.finally(() => {
					this.table_state.loading = false
				})
		},

		// 审核通过
		examineStore(status, item) {
			if (status == 1) {
				this.$confirm('确定通过改酒店审核吗', '', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
				})
					.then(() => {
						this.table_state.loading = true
						auditStore({
							id: item.id,
							examine_status: 1,
						})
							.then((res) => {
								if (res.code == 0) {
									this.$message({
										type: 'success',
										message: res.data as any,
									})
									this.getStoreListWithFilters()
									// let findIndex = this.table_data.findIndex((itemf: any) => {
									// 	return itemf.id == item.id
									// })
									// if (findIndex !== -1) {
									// 	this.$set(this.table_data[findIndex], 'examine_status', 1)
									// }
								}
							})
							.catch((res) => {
								this.$message({
									type: 'info',
									message: res.data,
								})
							})
							.finally(() => {
								this.table_state.loading = false
							})
					})
					.catch(() => {
						this.$message({
							type: 'info',
							message: `已取消操作`,
						})
					})
			} else if (status == 2) {
				this.editId = item.id
				this.showDialog = true
			}
		},

		changeLimit(data) {
			this.page = 1
			this.size = data
			this.getStoreListWithFilters()
		},
		changePages(data) {
			this.page = data
			this.getStoreListWithFilters()
		},
		submitMethod() {
			;(this.$refs['store_form'] as ElForm).validate((valid) => {
				if (valid) {
					let data = {
						...this.reason_submit_form,
						id: this.editId,
						examine_status: 2,
					}
					auditStore(data).then((res) => {
						if (res.code == 0) {
							this.showDialog = false
							this.$message({
								type: 'info',
								message: res.data as any,
							})
							this.getStoreListWithFilters()
							// let findIndex = this.table_data.findIndex((itemf: any) => {
							// 	return itemf.id == this.editId
							// })
							// if (findIndex !== -1) {
							// 	this.$set(this.table_data[findIndex], 'examine_status', 2)
							// }
							this.reason_submit_form.refuse_info = ''
						}
					})
				} else {
					return false
				}
			})
		},

		// // 上传成功回调
		// handleAvatarSuccess(res, file) {
		// 	this.store_submit_form.contract = res.data.base_url
		// },
		// // 删除文件回调
		// removeFIle() {
		// 	this.store_submit_form.contract = ''
		// },
		// // 上传检测文件格式
		// beforeAvatarUpload(file) {
		// 	let type = file.type
		// 	let arr = type.split('/')
		// 	let state = false
		// 	state = arr.indexOf('pdf') !== -1
		// 	if (!state) {
		// 		this.$message.error('图片上传格式错误，请重试')
		// 	}
		// 	return state
		// },
		// 关闭DIA
		closeDialog() {
			this.store_submit_form = {
				area_info: [],
				hotel_id: '',
				hotel_name: '',
				id_card_image1: '',
				id_card_image2: '',
				id_card_image: '',
				area_code: [],
				seller_name: '',
				mobile: '',
				discount: 10,
				commission_rate: 0,
				business_license: '',
				store_category_id: '',
				other_image: '',
			}
		},
		submitMethod2() {
			;(this.$refs['store_form'] as ElForm).validate((valid) => {
				if (valid) {
					this.btndisabled = true
					// eslint-disable-next-line no-undef
					let data: { [key: string]: any } = {
						...this.store_submit_form,
						admin_id: this.admin_id,
					}
					data.area_info = data.area_info.map((item) => item.label)
					data.discount = data.discount * 10
					// data.area_code = JSON.stringify(data.area_code)
					data.id_card_image = [data.id_card_image1, data.id_card_image2]
					delete data['id_card_image1']
					delete data['id_card_image2']

					submitADD(data)
						.then((res) => {
							if (res.code == 0) {
								this.showDialogStore = false
								this.$message({
									type: 'success',
									message: (res as any).data,
								})
								this.getStoreListWithFilters()
								this.$nextTick(() => {
									;(this.$refs['store_form'] as ElForm).resetFields()
								})
							}
						})
						.catch((err) => {
							this.$message({
								type: 'info',
								message: '添加失败',
							})
						})
						.finally(() => {
							this.btndisabled = false
						})
				} else {
					return false
				}
			})
		},
	},
})
