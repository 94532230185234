
























































































































































































































































































































import { adminToken } from '@/libs/local-store'
import Vue from 'vue'
import {
	storeAccountIndex,
	setStoreStatus,
	storeAccountDetail,
	confirmstoreAccountIndex,
	upLoadInvoice as upLoadInvoiceMethods,
	billDetailList,
} from '@/api/store'
import { ElForm } from 'element-ui/types/form'
import { getOssUrl, getUploadFileUrl } from '@/api/common'
import { getAdminInfo } from '@/api/admin'

export default Vue.extend({
	data() {
		return {
			comType: ['', '个人', '公司'],
			fileList1: [],
			fileList2: [],
			// 账单明细title
			bill_detail_title: '账单明细',
			bill_detail_id: '',
			bill_detail_list: [] as any,
			bill_detail_page: 1,
			bill_detail_size: 10,
			bill_detail_total: 0,

			showDialogBillList: false,
			getUploadFileUrl: getUploadFileUrl(),
			showDialog1: false,
			showDialog2: false,
			showDialogCommissionPrice: false,
			queTitle: '',
			labelPosition: 'right',
			statusList: [
				{ id: 0, name: '未划款' },
				{ id: 1, name: '已划款' },
			],
			searchForm: {
				name: '',
				status: '',
				account_date: '',
			},
			page: 1,
			size: 10,
			table_state: {
				loading: true,
			},
			total: 0,
			table_data: [] as any,
			store_submit_form: {
				id: '',
				payment_type: '', // 账户类型
				payment_name: '', // 账户名称
				payment_number: '', // 银行账号
				payment_bank: '', // 银行名称
				payment_bank_name: '', // 开户支行
				shop_price: 0,
			},
			store_submit_form_rules: {
				payment_bank: [{ required: true, message: '请输入银行名称', trigger: 'blur' }],
				invoice_number: [{ required: true, message: '请输入发票号码', trigger: 'blur' }],
				invoice_date: [{ required: true, message: '请选择银行名称', trigger: 'change' }],
				voucher_number: [{ required: true, message: '请输入划款流水凭证号', trigger: 'change' }],
				invoice_info: [{ required: true, message: '请上传发票', trigger: 'change' }],
			},
			store_submit_form_title: '新增商户',
			showDialog: false,
			settleDetailsInfo: {
				id: '',
				shop_price: '' as any,
				store: {
					payment_name: '',
					payment_number: '',
					payment_bank: '',
				},
				voucher_number: '',
				voucher_info: [] as string[],
			},
			baseUrl: '',
			uploadinvoiceShowDia: false,
			uploadinvoiceForm: {
				commission_price: '' as any,
				invoice_content: '',
				invoice_type: '1',
				invoice_number: '',
				invoice_form: '1',
				invoice_date: '',
				invoice_info: '',
				shop_price: '' as any,
				invoice_head_type: '',
				invoice_head: '',
				fileList: [] as any,
				invoice_address: '',
				invoice_mobile: '',
				invoice_bank_name: '',
				invoice_bank_number: '',
				invoice_status: '' as any,
			},
			editId: '',
			editType: false,
			table_info: [] as any,
			selectStoreName: '',
			TOKEN: '',
			hotel_ids: [],
		}
	},
	created() {
		getAdminInfo().then((res) => {
			if (res.code == 0) {
				this.hotel_ids = (res.data as any).hotel_ids
				this.storeAccountIndex()
			}
		})
		getOssUrl().then((res) => {
			this.baseUrl = res.data.domain.slice(0, -1)
		})
		this.TOKEN = adminToken.get()
	},
	methods: {
		storeAccountIndexNums() {
			this.page = 1
			this.storeAccountIndex()
		},
		// 查看分润详情
		checkcommission_price(item) {
			storeAccountDetail({
				id: item.id,
			}).then((res) => {
				if (res.code == 0) {
					let data = res.data[0]
					let info = {
						hotel_boss_commission: data.hotel_boss_commission,
						hotel_commission: data.hotel_commission,
						firm_commission: data.firm_commission,
					}

					this.table_info = [info]
					this.selectStoreName = data.store.hotel_name
					this.showDialogCommissionPrice = true
				}
			})
		},
		// 打开上传发票dia
		uploadinvoicem(item) {
			this.editId = item.id
			this.queTitle = '上传发票'

			storeAccountDetail({
				id: item.id,
			}).then((res) => {
				if (res.code == 0) {
					this.uploadinvoiceForm = res.data[0]
					if (this.uploadinvoiceForm.invoice_info !== '') {
						this.uploadinvoiceForm.fileList = [
							{
								path: this.baseUrl + this.uploadinvoiceForm.invoice_info,
								name: this.baseUrl + this.uploadinvoiceForm.invoice_info,
							},
						]
					}
					this.showDialog2 = true
				}
			})
		},
		submitcirformAccount(row) {
			// 划款状态:0=未划款,1=已划款 status
			this.$confirm('确定结算此条账单？', '', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.table_state.loading = true
					let data = {
						id: row.id,
					}
					confirmstoreAccountIndex(data)
						.then((res) => {
							if (res.code == 0) {
								let findIndex = this.table_data.findIndex((itemf) => {
									return itemf.id == row.id
								})
								if (findIndex !== -1) {
									this.$set(this.table_data[findIndex], 'status', 1)
								}
								this.showDialog = false
							}
						})
						.finally(() => {
							this.table_state.loading = false
						})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: `已取消操作`,
					})
				})
		},
		// 结算账单
		cirformAccount(row) {
			if (!row.store) {
				this.$message({
					type: 'info',
					message: '商户未填写收款账户',
				})
				return
			}
			this.store_submit_form = row.store
			this.store_submit_form.shop_price = row.shop_price || 0
			this.store_submit_form.id = row.id
			this.showDialog = true
		},
		// 关闭DIA
		closeDialog() {
			// console.log(11)
		},
		//  获取商户列表
		storeAccountIndex() {
			this.table_state.loading = true
			storeAccountIndex({
				...this.searchForm,
				index: this.page,
				limit: this.size,
				hotel_ids: this.hotel_ids,
				status: this.searchForm.status + '',
			})
				.then((res) => {
					if (res.code == 0) {
						// console.log(res.data)
						this.table_data = res.data.rows
						this.total = res.data.total
					}
				})
				.finally(() => {
					this.table_state.loading = false
				})
		},
		changeLimit(data) {
			this.page = 1
			this.size = data
			this.storeAccountIndex()
		},
		changeLimitBill(data) {
			this.bill_detail_page = 1
			this.bill_detail_size = data
			// 获取账单方法
			this.getbillDetailList(this.editId)
		},
		changePages(data) {
			this.page = data
			this.storeAccountIndex()
		},
		changePagesBill(data) {
			// 获取账单方法
			this.bill_detail_page = data
			this.getbillDetailList(this.editId)
		},
		submitShow1() {
			;(this.$refs['store_form'] as ElForm).validate((valid) => {
				if (valid) {
					console.log(1)
					let data = {
						id: this.settleDetailsInfo.id,
						voucher_number: this.settleDetailsInfo.voucher_number,
						voucher_info: this.settleDetailsInfo.voucher_info,
					}
					confirmstoreAccountIndex(data).then((res) => {
						if (res.code == 0) {
							this.showDialog1 = false
							this.$message({
								type: 'info',
								message: res.msg,
							})
							let findIndex = this.table_data.findIndex((itemf) => {
								return itemf.id == this.settleDetailsInfo.id
							})
							if (findIndex !== -1) {
								this.$set(this.table_data[findIndex], 'status', 1)
							}
						}
					})
				} else {
					return false
				}
			})
		},
		submitShow2() {
			;(this.$refs['invoice_form'] as ElForm).validate((valid) => {
				if (valid) {
					let data = {
						...this.uploadinvoiceForm,
					}
					// console.log(data)
					upLoadInvoiceMethods(data).then((res) => {
						if (res.code == 0) {
							this.showDialog2 = false
							this.$message({
								type: 'success',
								message: '操作成功',
							})
							let findIndex = this.table_data.findIndex((itemf) => {
								return itemf.id == this.editId
							})
							if (findIndex !== -1) {
								this.$set(this.table_data[findIndex], 'invoice_status', 3)
							}
							this.editId = ''
						}
					})
				} else {
					return false
				}
			})
		},
		showFile(event) {
			console.log(event)
			window.open(event.path)
		},

		getbillDetailList(id) {
			billDetailList({
				id: id,
				index: this.bill_detail_page,
				limit: this.bill_detail_size,
			}).then((res) => {
				if (res.code == 0) {
					// console.log(res)
					this.bill_detail_list = res.data.rows
					this.bill_detail_total = res.data.total
					this.showDialogBillList = true
				}
			})
		},

		checkInfo(row) {
			// console.log(row)
			// this.bill_detail_title = `账单名称${}`
			this.bill_detail_title = `账单明细 ${row.start_time.slice(0, 10)}至${row.end_time.slice(0, 10)}`
			this.editId = row.id
			this.bill_detail_page = 1
			this.getbillDetailList(this.editId)
		},
		beforeAvatarUpload(file) {
			let type = file.type
			let arr = type.split('/')
			let state = false
			state = arr.indexOf('pdf') !== -1
			if (!state) {
				this.$message.error('图片上传格式错误，请重试')
			}
			return state
		},
		handleAvatarSuccess(res, file) {
			this.uploadinvoiceForm.invoice_info = res.data.base_url
		},
		removeFIle() {
			this.uploadinvoiceForm.invoice_info = ''
		},
	},
})
