











































































import Vue from 'vue'
import {
	getGoodsCategory,
	addCategoryStoreDetail,
	editCategoryStoreDetail,
	deleteCategoryStoreDetail,
	updateCategoryStatus,
	getFirstAndSecondCategories,
} from '@/api/store'
import { ElForm } from 'element-ui/types/form'
import { adminToken } from '@/libs/local-store'

export default Vue.extend({
	data() {
		return {
			expands: [] as any,
			expandStatus: false,
			filterText: '', // 过滤树的值
			selectTreeValue: [] as any, // 选择树的值
			firstAndSecondCategories: [] as any,
			labelPosition: 'right',
			searchForm: {
				name: '',
			},
			// :0=已失败,1=生成订单,2=已完成
			statusList: [
				{ id: 1, name: '打开' },
				{ id: 0, name: '关闭' },
			],
			statusList2: [
				{ id: 1, name: '一级类目' },
				{ id: 2, name: '二级类目' },
				{ id: 3, name: '三级类目' },
			],
			page: 1,
			size: 10,
			table_state: {
				loading: true,
			},
			total: 0,
			table_data: [] as any,

			editId: '',
			store_submit_form_title: '编辑经营类别',
			showDialogStore: false,
			store_submit_form: {
				name: '',
				sort: 0,
				id: '',
				pid: '' as any,
				label: '',
				level: '',
			},
			store_submit_form_rules: {
				name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
				pid: [{ required: true, message: '请选择上级类目', trigger: 'change' }],
			},
			TOKEN: '',
			defaultProps: {
				children: 'children',
				label: 'label',
			},
			selectTreeArr: [] as any,
			treeKey: '' as any,
		}
	},
	created() {
		this.TOKEN = adminToken.get()
		this.getGoodsCategory()
		this.treeKey = +new Date()
	},
	watch: {
		filterText(val) {
			console.log((this.$refs as any).treeRef.filter(val))
			// ;(this.$refs as any).treeRef.filter(val)
		},
	},
	methods: {
		/** 表格某行点击事件 */
		handleRowClick(row, column, event) {
			console.log((this.$refs as any)['menuTable'])
			// 判断当前行是否有子集，若没有则结束处理
			if (row.children || row.children.length === 0) {
				return
			}
			this.$nextTick(() => {
				// this.$table![this.currentTable]!.toggleRowExpansion(row);
				const index = this.expands.indexOf(row.id)
				if (index >= 0) {
					this.expands.splice(index, 1)
				} else {
					this.expands.push(row.id)
				}
			})
			;(this.$refs as any)['menuTable'].toggleRowExpansion(row, true)
		},
		changeRed({ row }) {
			if (row.isshow == 1) {
				// 变颜色的条件
				return {
					backgroundColor: '#ecf5ff',
				}
			}
		},
		// 过滤节点
		filterNode(value, data) {
			if (!value) return true
			return data.label.indexOf(value) !== -1
		},
		// 移除Tag
		removeTag() {
			this.selectTreeValue.splice(0, 1)
			let setList = (this.$refs as any).treeRef.getCheckedNodes()
			setList.splice(0, 1)
			this.$nextTick(() => {
				;(this.$refs as any).treeRef.setCheckedNodes(setList)
			})
		},
		// 全部删除
		clearAll() {
			this.selectTreeValue = []
			this.selectTreeArr = []
			this.$nextTick(() => {
				;(this.$refs as any).treeRef.setCheckedNodes([])
			})
		},
		handleCheckChange(data, checked) {
			// // getCheckedNodes方法接收两个 boolean 类型的参数: 1. 是否只是叶子节点，默认值为 false 2. 是否包含半选节点，默认值为 false
			// let dataList = (this.$refs as any).treeRef.getCheckedNodes(true)
			// this.selectTreeValue = []
			// this.selectTreeArr = []
			// dataList.forEach((item) => {
			// 	this.selectTreeValue.push({ id: item.id, label: item.label })
			// 	this.selectTreeArr.push(item.label)
			// })
			console.log(data)
			let value = (this.$refs as any).treeRef.getCheckedKeys()
			if (value.length == 1 && checked) {
				;(this.$refs as any).treeRef.setCheckedKeys([data.id])
				this.store_submit_form.label = data.label
				this.store_submit_form.pid = data.id
				this.store_submit_form.level = data.level + 1
			}
			if (value.length !== 1 && checked) {
				;(this.$refs as any).treeRef.setCheckedKeys([data.id])
				this.store_submit_form.label = data.label
				this.store_submit_form.pid = data.id
				this.store_submit_form.level = data.level + 1
			}

			if (value.length == 0 && !checked) {
				this.store_submit_form.label = ''
				this.store_submit_form.pid = ''
				this.store_submit_form.level = ''
			}
		},
		getGoodsCategoryChange() {
			this.page = 1
			this.getGoodsCategory()
		},
		//  获取列表
		getGoodsCategory() {
			this.table_state.loading = true
			getGoodsCategory({
				...this.searchForm,
				page: this.page,
				size: this.size,
			})
				.then((res) => {
					if (res.code == 0) {
						this.treeKey = +new Date()
						if (this.searchForm.name == '') {
							this.expandStatus = false
						} else {
							this.expandStatus = true
						}
						if (res.data instanceof Array) {
							this.table_data = res.data
							// this.total = res.data.total
						} else {
							this.table_data = []
							this.total = 0
						}
					}
				})
				.finally(() => {
					this.table_state.loading = false
				})
		},
		// 修改最大值
		changeLimit(data) {
			this.page = 1
			this.size = data
			this.getGoodsCategory()
		},
		// 修改页数
		changePages(data) {
			this.page = data
			this.getGoodsCategory()
		},
		editStore(item) {
			console.log(item)
			this.store_submit_form_title = '编辑类目'
			this.filterText = ''
			this.store_submit_form.id = item.id
			this.store_submit_form.sort = item.sort
			this.store_submit_form.name = item.name

			this.showDialogStore = true
		},
		// 状态开启关闭
		changeStaus(event, item) {
			console.log(item)

			let changeStatus = event ? 1 : 0
			let data = {
				status: changeStatus,
				id: item.id,
			}
			let infoOpen = '确定要显示该类目吗？'
			let infoStop = '确定要隐藏该类目吗？'
			let info = event ? infoOpen : infoStop
			this.$confirm(info, '', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.table_state.loading = true
					updateCategoryStatus(data)
						.then((res) => {
							if (res.code == 0) {
								item.status = changeStatus
								this.$message({
									type: 'success',
									message: res.data as unknown as string,
								})
							}
						})
						.finally(() => {
							this.table_state.loading = false
						})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: `已取消操作`,
					})
				})
		},
		addStore() {
			this.store_submit_form_title = '新增类目'
			this.filterText = ''
			this.store_submit_form = {
				id: '',
				sort: 0,
				name: '',
				pid: '',
				label: '',
				level: '',
			}
			getFirstAndSecondCategories().then((res) => {
				this.firstAndSecondCategories = res.data.map((item) => {
					let cArr = []
					if (item.children.length > 0) {
						cArr = item.children.map((itemc) => {
							return {
								...itemc,
								label: itemc.name,
							}
						})
					}
					return {
						...item,
						label: item.name,
						children: cArr,
					}
				})
				this.firstAndSecondCategories.unshift({ id: 0, label: '一级类目', level: 0, children: [], pid: 0 })
			})
			// console.log(this.firstAndSecondCategories)
			this.$nextTick(() => {
				;(this.$refs as any).treeRef.setCheckedKeys([])
			})
			this.showDialogStore = true
		},
		submitMethod2() {
			;(this.$refs['store_form'] as ElForm).validate((valid) => {
				if (valid) {
					if (this.store_submit_form.id !== '') {
						let data = {
							id: this.store_submit_form.id,
							name: this.store_submit_form.name,
							sort: this.store_submit_form.sort,
						}
						editCategoryStoreDetail(data).then((res) => {
							if (res.code == 0) {
								this.showDialogStore = false
								this.$message({
									type: 'success',
									message: (res as any).data,
								})
								this.getGoodsCategory()
								this.$nextTick(() => {
									;(this.$refs['store_form'] as ElForm).resetFields()
								})
							}
						})
					} else {
						addCategoryStoreDetail({
							...this.store_submit_form,
						}).then((res) => {
							if (res.code == 0) {
								this.$message({
									type: 'success',
									message: (res as any).data,
								})
								this.showDialogStore = false

								this.getGoodsCategory()
								this.$nextTick(() => {
									;(this.$refs['store_form'] as ElForm).resetFields()
								})
							}
						})
					}
				} else {
					return false
				}
			})
		},
		// 关闭DIA
		closeDialog() {
			this.store_submit_form = {
				name: '',
				sort: 0,
				id: '',
				pid: '',
				label: '',
				level: '',
			}
		},
		que() {
			this.searchForm = {
				name: '',
			}
			this.page = 1
			this.size = 10
			this.getGoodsCategory()
		},
		updateCategorym(item) {
			this.$confirm('确定删除改类目吗', '', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.table_state.loading = true
					deleteCategoryStoreDetail({
						id: item.id,
					})
						.then((res) => {
							if (res.code == 0) {
								this.$message({
									type: 'success',
									message: res.data as any,
								})
								this.getGoodsCategory()
							}
						})
						.finally(() => {
							this.table_state.loading = false
						})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: `已取消操作`,
					})
				})
		},
	},
})
